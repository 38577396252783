import get from 'lodash/get'
import { reactive } from '@vue/composition-api'
import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'
import useDatepicker from '@/use/useDatepicker'
import { deleteBr } from '@/helper/Utils'

/* eslint-disable */
export default (ctx) => {
  const { moment } = useDatepicker(ctx)
  const formData = reactive({
    id: null,
    revision_id: null,
    title: '',
    description: '',
    content: '',
    media_type: 'lead_image',
    media_content: {
      image: [],
      video_url: '',
      video_file: [],
    },
    link_url: null,
    start_date: null,
    end_date: null,
    folder_name: null,
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  const dateRange = reactive({
    start_date: null,
    end_date: null,
  })

  const prepareFormData = (result) => {
    formData.id = result.id
    formData.title = result.title
    formData.description = deleteBr(result.description)
    formData.content = result.content
    formData.media_type = result.media_type
    formData.media_content = result.media_content
    formData.link_url = result.link_url
    formData.start_date = typeof result.start_date === 'string' ? new Date(result.start_date) : result.start_date
    formData.end_date = typeof result.end_date === 'string' ? new Date(result.end_date) : result.end_date
    // formData.start_date = result.start_date
    // formData.end_date = result.end_date
    dateRange.start_date = new Date(result.start_date)
    dateRange.end_date = new Date(result.end_date)
    formData.folder_name = result.folder_name
    formData.updated_at = result.updated_at
    formData.updated_by = result.updated_by
    formData.created_by = result.created_by
  }

  const fetchByRouteId = async () => {
    const revision_id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Image', 'VDO Thumbnail', 'VDO']
    if (revision_id) {
      formData.revision_id = revision_id
      ctx.root.$vs.loading()
      return await ctx.root.$store
        .dispatch('bannerHighlight/fetchOne', revision_id)
        .then((result) => {
          prepareFormData(result)
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`highlight/${formData.folder_name}`, folders)
        })
        .then(() => ctx.root.$vs.loading.close())
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`highlight/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  return {
    formData,
    dateRange,
    prepareFormData,
    fetchByRouteId,
  }
}
